<template>
    <div class="page-mode mb-12 page-declarations">
        <page-header class="page-declarations-head" subtitle="by vchain" title="Customs Declarations">
            <template slot="tabs">
                <div class="page-declarations-head__right">
                    <span class="f-size-16 f-w-medium mb-1 color-grey-65">Sofia Anderson</span>
                    <span class="f-size-12 color-grey-65">Sales manager @ Lenovo Supply Unit</span>
                </div>
            </template>
        </page-header>

        <filter-draggable 
            v-model="filters"
            name="declarations"
            :options="options"
            :header="header"
            :selects="selects"
            :switchs="switchs"
            @reset="resetData"
        />

        <card body-class="p-0 -mx-6 overflow-visible page-mode-table-body" class="border-radius page-mode-table">
            <app-table
                :branding="!adminRoute"
                :class="{'admin-table': adminRoute }"
                :columns="columns"
                :filter-params="filterParams"
                :filters="filters"
                :id="tableRef"
                :loading="true"
                :ref="tableRef"
                :tools="false"
                :panel="false"
                :panel-multi="true"
                :right-tools="false"
                :selected="checkedRows.length"
                :draggable="true"
                :sort-order="-1"
                :endpoint="$apiUrl.consignments.base"
                custom-checkable  
                paginate
                checkable
                query-params
                static
                serialize-params
                row-key="uuid"
                sort-key="ordered_at"
                class="t-draggable page-mode-table-content"
                @check-row="rowChecked"
            >
                
                <template slot="panel">
                    <div class="relative flex flex__align-center">
                        <icon
                            name="icons/download"
                            :disabled="checkedRows.length == 0"
                            class="icon-btn mr-4"
                            title="Export" 
                        />
                    </div>
                </template>

                <template slot="cell" slot-scope="{row, col}">
                    <span v-if="col.key == 'consignment_no'">
                        {{ row.consignment_no }}
                        
                        <button @click.prevent="copy(row.consignment_no)">
                            <fa :icon="['fal','copy']" class="ml-2 fa-icon" />
                        </button>
                    </span>

                    <span v-else-if="col.key == 'order_no'">
                        <router-link :to="`${linkToDetail}/1`" class="color-blue">{{ row.order_no }}</router-link>
                    </span>

                    <span v-else-if="col.key == 'ordered_at'">
                        <inline-input :title="row.ordered_at" />
                    </span>

                    <div v-else-if="col.key == 'actions'" class="flex flex__justify-end">
                        <a href="/" class="fa-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 10C20 4.47581 15.5242 0 10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10ZM1.93548 10C1.93548 5.54435 5.54435 1.93548 10 1.93548C14.4556 1.93548 18.0645 5.54435 18.0645 10C18.0645 14.4556 14.4556 18.0645 10 18.0645C5.54435 18.0645 1.93548 14.4556 1.93548 10ZM4.83871 10.8065V9.19355C4.83871 8.92742 5.05645 8.70968 5.32258 8.70968H10V6.00806C10 5.57661 10.5202 5.3629 10.8266 5.66532L14.8185 9.65726C15.0081 9.84677 15.0081 10.1532 14.8185 10.3427L10.8266 14.3347C10.5202 14.6411 10 14.4234 10 13.9919V11.2903H5.32258C5.05645 11.2903 4.83871 11.0726 4.83871 10.8065Z" fill="black" fill-opacity="0.1"/>
                            </svg>
                        </a>
                    </div>

                    <span v-else>{{ row[col.key] }}</span>
                </template>
            </app-table>
        </card>
    </div>
</template>

<script>
import AppTable from '~/components/vtable/AppTable';
import moment from 'moment'

import { filterParamsWatcher } from '~/utils/response';

import FilterDraggable from '~/components/FilterDraggable';
import InlineInput from './InlineInput'

export default {
    
    middleware: 'auth',

    name: 'CustomsDeclarations',

    components: {
        AppTable,
        FilterDraggable,
        InlineInput,
    },

    metaInfo() {
        return { title: 'Purchase processes' }
    },

    data: () => ({
        header: [
            {
                template: 'input',
                typeForm: 'input',
                name: 'input',
                model: 'input',
                optionsName: 'select'
            },
            {
                template: 'date',
                typeForm: 'date',
                name: 'Date',
                fullList: true,
                model: 'range',
            },
        ],
        selects: [],
        switchs: [
            {
                template: 'duplicates_by',
                typeForm: 'switch',
                name: 'D. bookings',
                model: 'duplicates_by',
                filter: true,
            },
        ],

        checkedRows: [],

        filters: {
            input: {
                value: 123,
            },
            range: {
                value: {
                    startDate: moment().subtract(30, 'days'),
                    endDate: moment()
                },
            },
            duplicates_by: {
                value: 0
            }
        },

        options: {
            select: ['multi', 'select']
        },

        columns: [
            {
                title: 'Consignment no.',
                key: 'consignment_no',
                toggled: true,
                sortable: true,
                pin: false,
                width: 'w-40',
                group: 'shipments'
            },
            {
                title: 'Reference',
                key: 'order_no',
                toggled: true,
                sortable: true,
                pin: false,
                width: 'w-32',
                group: 'shipments'
            },
            {
                title: 'Carrier service',
                key: 'carrier_service_from_source',
                toggled: false,
                width: 'w-48',
                sortable: true,
                group: 'shipments'
            },
            {
                title: 'Amount',
                key: 'noofpackages',
                toggled: false,
                alignRight: false,
                width: 'w-20',
                sortable: true,
                group: 'shipments'
            },
            {
                title: 'Site',
                key: 'owner_site',
                toggled: false,
                sortable: true,
                pin: false,
                width: 'w-32',
                group: 'customs'
            },
            {
                title: 'Date',
                key: 'ordered_at',
                toggled: true,
                width: 'w-48',
                sortable: true,
                group: 'customs'
            },
            {
                    title: '',
                    key: 'actions',
                    toggled: true,
                    sortable: false,
                    overflow: true,
                    width: 'w-12',
                    tdClass: 'actions',
                    pin: false,
                }
        ],
    }),

    computed: {
        adminRoute() {
            return this.$route.name.indexOf('admin') !== -1;
        },

        tableRef() {
            return 'customs-declarations';
        },

        user() {
            return this.$store.getters['auth/user']
        },

        filterParams() {
            return filterParamsWatcher(this.filters);
        },

        linkToDetail() {
            if (this.adminRoute) {
                return `/admin/customs-declarations`;
            }

            return `/customs-declarations`;
        }
    },

    methods: {
        rereshTable() {
            this.$refs[this.tableRef].table.fetchRows();
        },

        copy(value) {
            try {
                navigator.clipboard.writeText(value);

                this.$snotify.success(`Text copied`);
            } catch(error) {
                this.$snotify.success(`Error copy text`);
            }
        },

        resetData() {
            this.filters = {
                input: {
                    value: null,
                },
                range: {
                    value: {
                        startDate: moment().subtract(30, 'days'),
                        endDate: moment()
                    },
                },
                duplicates_by: {
                    value: 0
                }
            };

            this.$snotify.success(`Successfully`);
        },

        rowChecked(row) {
            if (row === 'checkAllRows') {
                this.checkedRows = this.$refs[this.tableRef].table.visibleRows.slice()
                return
            }

            if (row === 'uncheckAllRows') {
                this.checkedRows = []
                return
            }

            if (this.checkedRows.indexOf(row) !== -1) {
                this.checkedRows.splice(this.checkedRows.indexOf(row), 1)
            } else {
                this.checkedRows.push(row)
            }
        },
    },
}
</script>

<style lang="scss">
.page-declarations {
    .scroller {
        max-height: calc(100vh - (322px));
    }

    .fa-icon {
        color: rgba(0, 0, 0, 0.20);
        cursor: pointer;
        transition: color .2s;

        svg path {
            transition: fill-opacity .2s;
        }

        &:hover {
            color: rgba(0, 0, 0, 1);

            svg path {
                fill-opacity: 0.8;
            }
        }
    }

    .app-table__tr {
        background: transparent !important;
    }

    .vue-recycle-scroller__item-view {
        height: 42.96px !important;
    }

    .app-table__tbody .app-table__tr_loading:hover {
        .app-table__td.actions, .app-table__td.sticky {
            background-color: white !important;
        }
    }

    .app-table__td.sticky {
        background-color: white;
        height: 42px !important;
    }

    .app-table__td.actions {
        position: sticky;
        right: 0;
        padding-right: 1rem;
        background: white;
        height: 42px !important;
    }
}
</style>