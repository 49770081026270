<template>
    <card body-class="overflow-visible pb-0 flex flex__column">
        <div class="flex flex__column w__100-p drag-f-filters-drag border__bottom-grey" :class="{'drag-f-filters-drag--active': asFilterControl}">
            <div class="flex flex__justify-between w__100-p drag-f-hot-panel">
                <div class="flex flex__grow">
                    <draggable 
                        v-if="initDragg"
                        :list="filterHeader"
                        draggable=".item"
                        handle=".text-draggable"
                        :group="hotGroup"
                        class="flex flex__grow drag-f-hot-block drag-f-drag-zone"
                        :options="{disabled: !asFilterControl}"
                        :class="{'drag-f-drag-zone--active': asFilterControl && asFilterDrag }"
                        @start="onStart"
                        @end="asFilterDrag = false"
                    >
                        <filter-item 
                            v-for="item in filterHeader"
                            :model-value="modelValue[item.model].value"
                            :key="item.template"
                            :template="item.template"
                            :type-form="item.typeForm"
                            :adminRoute="false"
                            :user="user"
                            :options="options"
                            :loaded-range="loadedRange"
                            :datesTrigger="datesTrigger"
                            :filter="item.filter"
                            :filterModel="item.filterModel"
                            :optionsName="item.optionsName"
                            :createdComponents="createdComponents"
                            :branding="item.branding"
                            :class="item.className"
                            :name="item.name"
                            :label="item.label"
                            :placeholder="item.placeholder"
                            :track-by="item.trackBy"
                            :multiple="item.multiple"
                            :remove-all-item="item.removeAllItem"
                            :remove-default-value="item.removeDefaultValue"
                            :initAll="item.initAll"
                            class="item"
                            @update="update(item, $event)"
                        />
                    </draggable>
                </div>

                <div v-if="!asFilterControl" class="flex flex__align-center drag-f-hot-panel__bar">
                    <button @click="showAdvanced = !showAdvanced" title="Show all filters" type="button" class="icon-btn ml-4">
                        <fa :icon="['fal','filter']"/>
                    </button>
                    <div class="w-1 h-6 ml-4 border-r" />

                    <button title="Filter control" type="button" class="icon-btn ml-4" @click="onFilterControl">
                        <fa :icon="['fal','arrows-alt']"/>
                    </button>

                    <confirm @confirmed="$emit('reset')" confirm-text="Reset form">
                        <button title="Reset form" type="button" class="hover:text-red-500 icon-btn ml-4">
                            <fa :icon="['fal','undo']" />
                        </button>
                    </confirm>
                </div>

                <div v-else class="flex flex__align-center drag-f-hot-panel__bar">
                    <p>Save this layout?</p>

                    <div class="w-1 h-6 ml-4 border-r" />

                    <button type="button">
                        <svg-importer icon-name="icons/check" key="filter-draggable-confirm" />
                    </button>

                    <button title="Reset filters" type="button" class="ml-4" @click="cancelFilterControl">
                        <svg-importer name="icons/reset" key="filter-draggable-close" />
                    </button>
                </div>
            </div>

            <collapse-transition>
                <div class="flex flex-col w-full drag-f-all-filters" v-show="showAdvanced">
                    <h5 class="mt-3 mb-2">Advanced filters</h5>

                    <div class="flex flex__justify-between mt-4">
                        <div class="w-full pr-4">
                            <draggable 
                                v-if="initDragg"
                                :list="filterSelects"
                                draggable=".item"
                                handle=".text-draggable"
                                group="a"
                                class="flex flex__wrap w-full drag-f-selects-block drag-f-drag-zone"
                                :options="{disabled: !asFilterControl}"
                                :class="{'drag-f-drag-zone--active': asFilterControl && asFilterDrag && hotGroup === 'a'}"
                                @start="hotGroup = 'a'; asFilterDrag = true"
                                @end="asFilterDrag = false"
                            >
                                <filter-item 
                                    v-for="item in filterSelects"
                                    :model-value="modelValue[item.model].value"
                                    :key="item.template"
                                    :template="item.template"
                                    :type-form="item.typeForm"
                                    :adminRoute="false"
                                    :user="user"
                                    :options="options"
                                    :loaded-range="loadedRange"
                                    :datesTrigger="datesTrigger"
                                    :filter="item.filter"
                                    :filterModel="item.filterModel"
                                    :optionsName="item.optionsName"
                                    :createdComponents="createdComponents"
                                    :branding="item.branding"
                                    :class="item.className"
                                    :name="item.name"
                                    :label="item.label"
                                    :placeholder="item.placeholder"
                                    :track-by="item.trackBy"
                                    :multiple="item.multiple"
                                    :remove-all-item="item.removeAllItem"
                                    :remove-default-value="item.removeDefaultValue"
                                    :initAll="item.initAll"

                                    class="item mr-4 mb-4"
                                    @update="update(item, $event)"
                                />
                            </draggable>
                        </div>

                        <div 
                            class="relative drag-f-switch-block-wrapper drag-f-drag-zone" 
                            :class="{'drag-f-drag-zone--active': asFilterControl && asFilterDrag && hotGroup === 'b'}"
                        >
                            <draggable 
                                v-if="initDragg"
                                :list="filterSwitch"
                                draggable=".item"
                                handle=".text-draggable"
                                group="b"
                                class="drag-f-switch-block"
                                :options="{disabled: !asFilterControl}"
                                @start="hotGroup = 'b'; asFilterDrag = true"
                                @end="asFilterDrag = false"
                            >
                                <filter-item 
                                    v-for="item in filterSwitch"
                                    :model-value="modelValue[item.model].value"
                                    :key="item.template"
                                    :template="item.template"
                                    :type-form="item.typeForm"
                                    :adminRoute="false"
                                    :user="user"
                                    :options="options"
                                    :loaded-range="loadedRange"
                                    :datesTrigger="datesTrigger"
                                    :filter="item.filter"
                                    :filterModel="item.filterModel"
                                    :optionsName="item.optionsName"
                                    :createdComponents="createdComponents"
                                    :branding="item.branding"
                                    :class="item.className"
                                    :name="item.name"
                                    :label="item.label"
                                    :placeholder="item.placeholder"
                                    :track-by="item.trackBy"
                                    :multiple="item.multiple"
                                    :remove-all-item="item.removeAllItem"
                                    :remove-default-value="item.removeDefaultValue"
                                    :initAll="item.initAll"
                                    @update="update(item, $event)"

                                    class="item"
                                />
                            </draggable>
                        </div>
                    </div>
                </div>
            </collapse-transition>
        </div>
    </card>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Draggable from 'vuedraggable';
import FilterItem from '~/components/ShipmentsAdmin/FilterItem';

export default {
    components: {
        Draggable,
        FilterItem,
        CollapseTransition
    },

    model: {
        prop: 'modelValue',
        event: 'update'
    },

    props: {
        options: {
            type: Object,
            default: () => { return {} },
        },

        header: {
            type: Array,
            default: () => { return [] },
        },

        selects: {
            type: Array,
            default: () => { return [] },
        },

        switchs: {
            type: Array,
            default: () => { return [] },
        },

        modelValue: {
            type: Object,
            default: () => { return {} },
        },

        name: {
            type: String,
            default: 'vchain',
        },
    },

    data() {
        return {
            initDragg: false,
            createdComponents: true,
            hotGroup: 'a',
            asFilterControl: false,
            asFilterDrag: false,
            copyFiltersDrag: { filterHeader: [], filterSelects: [], filterSwitch: [] },
            datesTrigger: 0,
            showAdvanced: false,
            loadedRange: false,

            filterHeader: this.header,
            filterSelects: this.selects,
            filterSwitch: this.switchs,
        }
    },

    computed: {
        vue() {
            return this;
        },

        user() {
            return this.$store.getters['auth/user']
        },
    },

    methods: {
        onStart(e) {
            this.asFilterDrag = true;

            const result = (e.item.className.indexOf('checkbox') + 1 || e.item.className.indexOf('switch') + 1);

            if (result) {
                this.hotGroup = 'b';

                return;
            }

            this.hotGroup = 'a';
        },

        onFilterControl() {
            this.copyFiltersDrag = {
                filterHeader: [...this.filterHeader],
                filterSelects: [...this.filterSelects],
                filterSwitch: [...this.filterSwitch]
            }

            this.asFilterControl = true; 
            this.showAdvanced = true;
        },

        cancelFilterControl() {
            this.filterHeader = this.copyFiltersDrag.filterHeader;
            this.filterSelects = this.copyFiltersDrag.filterSelects
            this.filterSwitch = this.copyFiltersDrag.filterSwitch;

            this.asFilterControl = false;
            this.showAdvanced = false;
        },

        saveFilterControl() {
            localStorage.setItem(`${this.name}-select`, JSON.stringify(this.filterSelects));
            localStorage.setItem(`${this.name}-header`, JSON.stringify(this.filterHeader));
            localStorage.setItem(`${this.name}-switch`, JSON.stringify(this.filterSwitch));

            this.asFilterControl = false;
            this.showAdvanced = false;

            this.$snotify.success(`Filters saved successfully`);
        },

        initFilterControl() {
            const filtersHeader = localStorage.getItem(`${this.name}-header`);
            const filtersSelects = localStorage.getItem(`${this.name}-select`);
            const filtersSwitch = localStorage.getItem(`${this.name}-switch`);

            if (filtersHeader) {
                this.filterHeader = JSON.parse(filtersHeader);
            }

            if (filtersSelects) {
                this.filterSelects = JSON.parse(filtersSelects);
            }

            if (filtersSwitch) {
                this.filterSwitch = JSON.parse(filtersSwitch);
            }
        },

        update(item, event) {
            const value = Object.assign({}, this.modelValue);

            value[item.model].value = event;

            this.$emit('update', value);
        }
    },

    mounted() {
        this.initDragg = true;

        // this.initFilterControl();
    },
}
</script>